import React from "react";

import "./Header.css";

import Logo from "../Images/Logo.svg";

import { Link } from "react-scroll";

function Header() {
  
  return (
    <header id="header">
      <div className="row header__row">
      
        <div className="header__text">
       
          <h1 className="header__title">
            Life Reset,<br /> Life Organisation,<br/>Life Management<br/>
          </h1>
          <p className="header__para">Your Pathway to Personal Growth,<br />Home Harmony, and Financial Freedom. </p>
          <div className="header__text__links">
          <Link to="about-bls" smooth={true} duration={800} className="header__button">
              Learn More
            </Link>
          </div>
        </div>
        <img src={Logo} alt="" className="header__image"/>
      </div>
    </header>
  );
}

export default Header;
