import React from "react";
import "./AboutBLS.css";
import AboutblsImage from "../Images/AboutblsImage.png";
export default function AboutBLS() {
  return (
    <section id="about-bls">

      <div className="row about-bls__row">
        <div className="about-bls__text">
          <h2 className="about-bls__title">
            Welcome To <br />
            <span className="bliss-blue"> Bliss Life Solutions</span>
          </h2>
          
          <p className="about-bls__para">
            Where you can find the solutions to
            help you live your best life. My name is Elisha, I’m a certified
            life coach with a passion for helping others. I know how hard life
            can be sometimes, and how easy it is to feel lost, overwhelmed, or
            stuck.<br/><br/> I’ve been through many challenges and changes in my life, and
            I’ve learned how to overcome them and create a life of bliss. Now, I
            want to share my knowledge and experience with you, helping you
            achieve your own goals and dreams. I have a unique coaching style
            that sets me apart from others as my number one priority is your
            growth. <br/><br/>I’m not here to pamper you, I’m here to challenge you. My
            coaching style is straightforward and truthful. I choose not to
            sugarcoat the facts. Telling it like it is empowers my clients to
            improve rapidly reaching their goals quickly while owning their
            actions along the way.
          </p>
        </div>
        <img src={AboutblsImage} alt="" className="about-bls__image" />
      </div>
    </section>
  );
}
