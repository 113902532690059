import React from "react";
import "./Card.css";

import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";

function Card({ title, cardDes, Para, img, OnQuickEnquire, onLearnMore, icon }) {
  const selectedIcon = Icons[icon];
  

  return (
    <div className="card">
      <FontAwesomeIcon icon={selectedIcon} className="card__icon"/>
      <div className="card__titles">
        <h5 className="card__title">{title}</h5>
      </div>
      <div className="card__rule-container">
        <div className="card__rule"> </div>
      </div>
      <div className="card__container">
        <div className="card__text">
          <p className="card__para">{cardDes}</p>
        </div>
        <div className="card__buttons">
          <button className="card__button" onClick={onLearnMore}>
            <a className="card__button-inner">
              Learn More
            </a>
          </button>
          <span className="card__button">
            <Link
              to="enquire"
              smooth={true}
              duration={500}
              className="card__button"
              onClick={OnQuickEnquire}
            >
              Quick Enquire
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Card;
