import React, { useState } from 'react'
import "./Question.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp, faLink } from '@fortawesome/free-solid-svg-icons'
function Question({title, para}) {
    const [showMore, setShowMore] = useState(false)

   const handleClick = () =>{
    setShowMore(!showMore)
   }
  return (
    <div className={`question__wrapper  ${showMore ? "question__wrapper-showmore" : ''}`} >
        <div className={`question__container  ${showMore ? "question__container-showmore" : ''}`} >
        <h3 className="question__title" onClick={handleClick}>{title} 
          <FontAwesomeIcon icon={showMore ? faAngleUp : faAngleDown} /></h3>        
        <div className={`question__text  ${showMore ? "question__text-showmore" : ''}`}>
            <p className="question__para ">{para}</p>
        </div>
        </div>
      
    </div>
  )
}

export default Question