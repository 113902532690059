import {React, useContext} from "react";
import ServiceBG from "../Images/serviceBG.png"
import { quickEnquireContext } from "../../App";
import { useNavigate, } from "react-router-dom";
import './Services.css';
import Card from "../Card/Card";

function Services({cardData}) {
  const {quickEnquireData,setQuickEnquireData} = useContext(quickEnquireContext)
  const navigate = useNavigate();


  const handleCardClick = (title) => {
    setQuickEnquireData(title)
    console.log(quickEnquireContext)
  }

  const handleLearnMoreClick = (card) => {
    console.log(card)
    navigate(`/Service/${card.id}`, { state: { card } });
    window.scrollTo(0, 0);
  };
  console.log(cardData)
  return (
    <section id="services">
      <h3 className="services__subtitle">SERVICES</h3>
      <div className="row services__row">
      

      
        <div className="cards">
            {cardData.map((card) => 
                <Card
                key={card.id}
                icon={card.icon}
                title={card.title}
                subtitle={card.subtitle}
                cardDes={card.cardDes}
                Para={card.Para}
                img={card.img}
                OnQuickEnquire={() => handleCardClick(card.title)}
                onLearnMore={() => handleLearnMoreClick(card)}
              />
            )}
        </div>
      </div>
    </section>
  );
}

export default Services;
