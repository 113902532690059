import React from "react";
import "./AboutMe.css";
import AboutMeImage from "../Images/AboutMeImage.png";
export default function AboutMe() {
  return (
    <section id="about-me">
      <div className="row about-me__row">
        <img src={AboutMeImage} alt="" className="about-me__image" />
        <div className="about-me__text">
          <p className="about-me__para">
            <b>I’ve worked in various fields</b>, from childcare to accounting,
            owned a cleaning, organising and decluttering business worked in
            civil construction and power stations in male dominated industries.
            I have been the spouse working away and have also been the spouse at
            home raising the children.
            <br />
            <br /> I’ve been married, divorced, and have since found new love
            with a man who has three children of his own forming our own version
            of a blended family.
            <br />
            <br /> I am well-travelled. I’ve raised two amazing children on my
            own who are now living their own blissful lives. And through it all,
            I’ve learned valuable lessons that have shaped me into who I am
            today. I believe we should never stop growing and learning so let’s
            move forward together.
            <br />
            <br />
            <b>
            </b>
          </p>
        </div>
      </div>
    </section>
  );
}
