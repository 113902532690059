import React from "react";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareFacebook } from "@fortawesome/free-brands-svg-icons";
import WhiteLogo from "../Images/WhiteLogo.svg";
import { useViewport } from "react-viewport-hooks";

import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import NavHamburger from "../NavHamburger/NavHamburger";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-scroll";
function Navbar({ main }) {
  const { vw, vh } = useViewport();

  return (
    <nav id="navbar">
      <div className=" row nav__row">
        <div className="nav__info__container">
          <HashLink className="nav__link" to="/#">
            <img
              className="nav__logo"
              href="#"
              src={WhiteLogo}
              alt="Bliss Logo"
            />
          </HashLink>

          {vw > 1000 ? (
            <div className="nav__info__container">
              <a className="nav__info" id="phone" href="tel:+610418158541">
                <FontAwesomeIcon icon={faPhone} />
                0418158541
              </a>
              <a
                className="nav__info"
                id="email"
                href="mailto: blisslifesolutions@outlook.com"
              >
                <FontAwesomeIcon icon={faEnvelope} />{" "}
                blisslifesolutions@outlook.com
              </a>
              <a
                className="nav__info"
                href="https://www.facebook.com/BlissLifeSolutions/"
              >
                <FontAwesomeIcon icon={faSquareFacebook} />
              </a>
            </div>
          ) : (
            <div />
          )}
        </div>
        {vw > 1000 ? (
          <div className="nav__links">
            {main ? (
              <>
                <Link
                  className="nav__link"
                  smooth
                  to="about-bls"
                  duration={500}
                >
                  About
                </Link>

                <Link className="nav__link" smooth to="services" duration={500}>
                  Services
                </Link>

                <Link
                  className="nav__link"
                  smooth
                  to="testimonials"
                  duration={500}
                >
                  Testimonials
                </Link>

                <Link className="nav__link" smooth to="faq" duration={500}>
                  FAQ
                </Link>

                <Link
                  className="nav__button"
                  to="enquire"
                  smooth={true}
                  duration={500}
                >
                  Enquire
                </Link>
              </>
            ) : (
              <>
                <HashLink className="nav__link" to="/#about-bls">
                  About
                </HashLink>

                <HashLink className="nav__link" to="/#services">
                  Services
                </HashLink>

                <HashLink className="nav__link" to="/#testimonials">
                  Testimonials
                </HashLink>

                <HashLink className="nav__link" to="/#faq">
                  FAQ
                </HashLink>

                <HashLink className="nav__button" to="/#enquire">
                  Enquire
                </HashLink>
              </>
            )}
          </div>
        ) : (
          <NavHamburger main={main} />
        )}
      </div>
    </nav>
  );
}

export default Navbar;
