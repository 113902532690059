import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";
import Home from "./Screens/Home/Home";
import Service from "./Screens/LearnMore/Service";
import { useState, createContext, useEffect } from "react";

export const quickEnquireContext = createContext();
function App() {
  const [quickEnquireData, setQuickEnquireData] = useState(null);

  useEffect(() =>{
    console.log("Made For Lishy from Riley. Good Luck With your business hope it takes you far.")
    },[])

    
  return (
    <div className="app">
      <quickEnquireContext.Provider
        value={{ quickEnquireData, setQuickEnquireData }}
      >
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Service/:id" element={<Service />} />
          </Routes>
        </Router>
      </quickEnquireContext.Provider>
    </div>
  );
}

export default App;
