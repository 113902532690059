import Navbar from "../../Components/Navbar/Navbar";
import { quickEnquireContext } from "../../App";
import { HashLink } from "react-router-hash-link";
import "./Service.css";
import { useLocation } from "react-router-dom";
import { React, useContext } from "react";
import Footer from "../../Components/Footer/Footer";

function Service() {
  const { quickEnquireData, setQuickEnquireData } =
    useContext(quickEnquireContext);
  const location = useLocation();
  console.log("Location state:", location.state);
  const { card } = location.state || {};

  const handleCardClick = (title) => {
    setQuickEnquireData(title);
    console.log(quickEnquireContext);
  };

 
  const parseHtmlString = (htmlString) => {
    return { __html: htmlString.replace(/<br\s*\/?>/g, '<br>') };
  };

  return (
    <>

      <section id="service">
      <Navbar main={false} />
        <div className="row service__row">
          <div className="service__text">
            <h2 className="service__title">{card.title}</h2>
            <p className="service__para" dangerouslySetInnerHTML={parseHtmlString(card.para)}></p>
            <HashLink
              to="/#enquire"
              onClick={() => handleCardClick(card.title)}
              className="service__button"
            >
              Enquire
            </HashLink>
          </div>

          <img src={card.img} alt="" className="service__image" />
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default Service;