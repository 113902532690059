import React, { useEffect, useState } from "react";
import "./NavHamburger.css";
import { Link } from "react-scroll";
import { HashLink } from "react-router-hash-link";

function NavHamburger({main}) {
  const [active, setActive] = useState(false);
  const [scrollLocked, setScrollLocked] = useState(false);

  const handleClick = () => {
    setActive(!active);
    setScrollLocked(!scrollLocked);
  };

  useEffect(() => {
    const bodyStyle = document.body.style;
    bodyStyle.overflowY = active ? "hidden" : "auto";

    return () => {
      bodyStyle.overflowY = "auto";
    };
  }, [active]);

  useEffect(() => {
    const handleTouchMove = (e) => {
      if (scrollLocked) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, [scrollLocked]);

  return (
    <div className="navhamburger">
      <button
        className={`navhamburger__button ${active ? "hamburger__active" : ""}`}
        onClick={handleClick}
      >
        <div className="bar"></div>
      </button>
      {active && (
        <div className="mobile__nav">
          {main ? (
              <>
              <Link
              className="mobile__button"
              smooth
              to="about-bls"
              duration={500}
              onClick={handleClick}
            >
              About
            </Link>

            <Link
              className="mobile__button"
              smooth
              to="services"
              duration={500}
              onClick={handleClick}
            >
              Services
            </Link>

            <Link
              className="mobile__button"
              smooth
              to="testimonials"
              duration={500}
              onClick={handleClick}
            >
              Testimonials
            </Link>

            <Link
                className="mobile__button"
                smooth to="faq"
                duration={500}
                onClick={handleClick}
              >
                FAQ
              </Link>

              <Link
              className="mobile__button"
              to="enquire"
              smooth={true}
              duration={500}
              onClick={handleClick}
            >
              Enquire
            </Link>
              </>
              

            
            ) : (
              <>
              <HashLink
              className="mobile__button"
              to="/#about-bls"
              onClick={handleClick}
            >
              About
            </HashLink>

            <HashLink
              className="mobile__button"
              to="/#services"
              onClick={handleClick}
            >
              Services
            </HashLink>

            <HashLink
              className="mobile__button"
              to="/#testimonials"
              onClick={handleClick}
            >
              Testimonials
            </HashLink>

            <HashLink
                className="mobile__button"
                to="/#faq"
                onClick={handleClick}
              >
                FAQ
              </HashLink>

              <HashLink
                className="mobile__button"
                to="/#enquire"
                onClick={handleClick}
              >
                Enquire
              </HashLink>
              </>
              
            )}
        </div>
      )}
    </div>
  );
}

export default NavHamburger;
