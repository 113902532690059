import React, { useState } from "react";
import testimonialpfp1 from "../Images/testimonialpfp1.png";
import testimonialpfp2 from "../Images/testimonialpfp2.png";
import "./Testimonial.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
export default function Testimonial({ para, summery, name, gender}) {
  const [showMore, setShowMore] = useState(false);
  return (
    <div
      className={`testimonial__container ${
        showMore ? "testimonial__container-showmore" : " "
      }`}
    >
      <div className="testimonial__image__container">
        <img src={gender === "F" ? testimonialpfp2 : testimonialpfp1} alt="" className="testimonial__image" />
      </div>

      <div className="testimonial__text">
        <span className="testimonial__name">{name}</span>
        <p
          className={`testimonial__para ${
            showMore ? "testimonial__para-showmore" : " "
          }`}
        >
          {showMore ? para : summery}
        </p>
      </div>
      <div
        className={`testimonial__button__container ${
          showMore ? "testimonial__button__container-showmore" : " "
        }`}
      >
        <button
          className="testimonial__button"
          onClick={() => setShowMore(!showMore)}
        >
          {!showMore ? "Show More" : "Show Less"}{" "}
          <FontAwesomeIcon icon={showMore ? faAngleUp : faAngleDown} />
        </button>
      </div>
    </div>
  );
}
