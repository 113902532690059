// Import the functions you need from the SDKs you need
import { initializeApp} from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configurationfirebase 
const firebaseConfig = {
  apiKey: "AIzaSyBa7bZyB6E5Arwl6WlwGzKP9hZZ5DhSFo4",
  authDomain: "bliifesolutions.firebaseapp.com",
  projectId: "bliifesolutions",
  storageBucket: "bliifesolutions.appspot.com",
  messagingSenderId: "355792911249",
  appId: "1:355792911249:web:f79ccd74a3928dc5ed7830"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp