import React from "react";
import "./Featured.css";
import Feature from "../Freature/Feture";

function Featured() {
  return (
    <section id="featured">
      <div className="row featured__row">
        <Feature
          featureIcon={`lock`}
          title={`Confidential`}
          para={`Bliss Life Solutions guarantees a non-judgmental and completely confidential environment, ensuring client privacy and trust in my services.`}
        />
        <Feature
          featureIcon={`globe`}
          title={`Global Coaching`}
          para={`Bliss Life Solutions is accessible from anywhere offering flexible and remote services. Communicating with clients via phone, email, or zoom.`}
        />
        <Feature
          featureIcon={`coins`}
          title={`Cost Effective`}
          para={`So everyone has the opportunity to receive help. My services are economically accessible to all, flexible pricing reflects the duration, scope, and intensity of the sessions.`}
        />
      </div>
    </section>
  );
}

export default Featured;
