import React from "react";
import "./Testimonials.css";
import Testimonial from "../Testimonial/Testimonial";
import { useWindowSize } from "@react-hook/window-size";
function Testimonials() {
  const [width, height] = useWindowSize();
  return (
    <section id="testimonials">
      <h3 className="testimonial__subtitle">TESTIMONIALS</h3>
      <div className="row testimonials__row">
        
        <Testimonial
          summery={`"I can’t thank Elisha enough for her amazing coaching and support. She helped me turn my life around and achieve things I never thought possible."`}
          para={`“I can’t thank Elisha enough for her amazing coaching and support. She helped me turn my life around and achieve things I never thought possible. She helped me declutter my home, organise my finances, and start my own business. She also helped me improve my relationships, boost my confidence, and follow my passion. She is more than just a coach, she is a friend, a mentor, and an inspiration.”`}
          name={`SS QLD Female`}
          gender={`F`}
        />
        <Testimonial
          summery={`"Elisha is a good life coach who has helped me change my life for the better. She really got to the point fast and quick."`}
          para={`"Elisha is a good life coach who has helped me change my life for the better. She really got to the point fast and quick. She helped me set goals for my life, and a plan to make it happen. I was so lost and had no idea where to start."`}
          name={`MD QLD Male`}
          gender={`M`}
        />

        <Testimonial
          summery={`"Elisha is a wonderful life coach who has made a huge difference in my life. She helped me overcome my fears and insecurities"`}
          para={`"Elisha is a wonderful life coach who has made a huge difference in my life. She helped me overcome my fears and insecurities, and taught me how to find my strengths, set much needed boundaries and follow my dreams. She helped me set realistic and achievable goals, and supported me every step of the way, she genuinely cares about her clients and their happiness. I highly recommend Elisha.”`}
          name={`LP NSW Female`}
          gender={`F`}
        />
      </div>
    </section>
  );
}

export default Testimonials;
