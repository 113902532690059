import React from "react";
import "./Feature.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faGlobe, faLock } from "@fortawesome/free-solid-svg-icons";

function Feature({ featureIcon, title, para }) {
  return (
    <div className="feature__text">
      <div className="feature__heading">
        {featureIcon === "coins" && <FontAwesomeIcon icon={faCoins} />}
        {featureIcon === "globe" && <FontAwesomeIcon icon={faGlobe} />}
        {featureIcon === "lock" && <FontAwesomeIcon icon={faLock} />}
        <h5 className="feature__title">{title}</h5>
      </div>
      <p className="feature__para">{para}</p>
    </div>
  );
}

export default Feature;
