import React from "react";
import "./FAQ.css";
import Question from "../Question/Question";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLink, faPhone } from "@fortawesome/free-solid-svg-icons";

function FAQ() {
  return (
    <section id="faq">
      <div className="row faq__row">
        <h3 className="services__subtitle">FREQUENTLY ASKED QUESTIONS</h3>
        <div className="faq__container">
          <Question
            title={"What is life coaching? And how does it work?"}
            para={
              <>
                Life coaching{" "}
                <span className="blue">
                  <b>empowers</b>
                </span>{" "}
                individuals to create their own
                <span className="blue">
                  <b> fulfilling life</b>
                </span>
                . As your coach, I’ll help you understand your values,
                strengths, and direction. <br />
                <br /> Expect{" "}
                <span className="blue">
                  <b>powerful</b>
                </span>{" "}
                conversations where we assess your current situation, clarify
                your desires, and set inspiring goals. Coaches emphasise both
                long-term dreams and{" "}
                <span className="blue">
                  <b>achievable</b>
                </span>{" "}
                milestones. <br />
                <br /> Together, we’ll cultivate positivity and{" "}
                <span className="blue">
                  <b>self-belief</b>
                </span>
                , implementing necessary changes all based around your own
                personal story and needs.
              </>
            }
          />
          <Question
            title={"Is a life coach the same as a counsellor?"}
            para={
              <>
                Life coaches focus on{" "}
                <span className="blue">
                  <b>accountability</b>
                </span>{" "}
                and future-oriented guidance, helping clients achieve their
                goals. Counsellors, on the other hand, address past issues,
                treat mental illness, addictions, and past trauma.
                <br />
                <br />
                <span className="blue">
                  <b>Please Note: </b>
                </span>
                As Life coaching and therapy serve different purposes. If you’re
                dealing with past issues, addiction, or recent trauma, seek
                professional help from a therapist or social worker.
                <br />{" "}
              </>
            }
          />
          <Question
            title={"What are the benifites of coaching via zoom and phone?"}
            para={
              <>
                Zoom coaching is equally{" "}
                <span className="blue">
                  <b>effective</b>
                </span>{" "}
                as face-to-face coaching. Whether you’re in a downtown office or
                your cozy study, the results remain consistent. Coaching via
                Zoom and phone allows me to offer{" "}
                <span className="blue">
                  <b>enhanced</b>
                </span>{" "}
                services and reach clients beyond the local area.
                <br />
                <br /> It’s{" "}
                <span className="blue">
                  <b>efficient</b>
                </span>
                , saving time without the need to travel. Additionally, distance
                fosters{" "}
                <span className="blue">
                  <b>openness</b>
                </span>
                , enabling clients to share freely over the phone or Zoom.
              </>
            }
          />
          <Question
            title={"What should I expect during our sessions?"}
            para={
              <>
                During our sessions, we’ll delve into your specific future
                <span className="blue">
                  <b> goals</b>
                </span>
                . As your life coach, I’m here to provide the{" "}
                <span className="blue">
                  <b>crucial</b>
                </span>
                support you need to gain traction towards achieving these goals.
                <br />
                <br />
                You can count on professionalism and{" "}
                <span className="blue">
                  <b>confidentiality</b>
                </span>{" "}
                throughout our journey together. I am here to{" "}
                <span className="blue">
                  <b>empower</b>
                </span>{" "}
                you to make swift progress in your life, taking responsibility
                for your actions along the way.
              </>
            }
          />
          <Question
            title={"What sets me apart from other life coaches?"}
            para={
              <>
                I don’t believe in{" "}
                <span className="blue">
                  <b>sugarcoating</b>
                </span>{" "}
                the facts, I’m here to challenge you. My coaching style is{" "}
                <span className="blue">
                  <b>straightforward</b>
                </span>
                , designed to empower you to make rapid improvements while
                taking ownership of your actions. <br />
                <br /> I communicate candidly, much{" "}
                <span className="blue">
                  <b>like a best friend</b>
                </span>{" "}
                would, and I’m not afraid to use strong language when necessary.
                My
                <span className="blue">
                  <b> authenticity</b>
                </span>{" "}
                is a core part of who I am, and I encourage you to embrace your
                authentic self as well.
              </>
            }
          />
          <Question
            title={"How long does coaching typically last?"}
            para={
              <>
                Firstly we will start off with a single <span className="blue"><b>60-minute session</b></span>. For
                some clients, this initial session suffices, while others opt
                for a more extended coaching journey.<br/> <br/> I value flexibility,
                understanding that everyone’s timeline will differ based on
                their unique needs, goals, and pace of growth. For those seeking
                a deeper dive, I offer <span className="blue"><b>extended sessions</b></span> that last 2 hours or
                more. These allow for comprehensive exploration, profound
                insights, and robust support. Typically, most clients find that
                a coaching program spanning <span className="blue"><b>3 to 12 months</b></span> suits their needs
                best.
              </>
            }
          />
        </div>
      </div>
    </section>
  );
}

export default FAQ;
