import React from "react";
import "./footer.css";
import { Link} from "react-scroll"
import Logo from "../Images/FooterLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { HashLink } from "react-router-hash-link";

function Footer({main}) {
  return (
    <footer id="footer">
      <div className="row footer__row">
        <div className="footer__container">
          <img className="footer__logo" href="#" src={Logo} alt="Bliss Logo" />
          <div className="footer__links">
            <span className="footer__title">Contact Information:</span>
            <a className="footer__link" id="phone" href="tel:+610418158541">
              <FontAwesomeIcon icon={faPhone} />
              0418158541
            </a>
            <a
              className="footer__link"
              id="email"
              href="mailto: blisslifesolutions@outlook.com"
            >
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              blisslifesolutions@outlook.com
            </a>
          </div>

          <div className="footer__links">
            <span className="footer__title">Navigation:</span>
            {main ? (
              <>
              <Link
              className="footer__link-nav"
              smooth
              to="about-bls"
              duration={500}
            >
              About
            </Link>

            <Link
              className="footer__link-nav"
              smooth
              to="services"
              duration={500}
            >
              Services
            </Link>

            <Link
              className="footer__link-nav"
              smooth
              to="testimonials"
              duration={500}
            >
              Testimonials
            </Link>

            <Link
                className="footer__link-nav"
                smooth to="faq"
                duration={500}
              >
                FAQ
              </Link>

              </>
              

            
            ) : (
              <>
              <HashLink
              className="footer__link-nav"
              to="/#about-bls"
              
            >
              About
            </HashLink>

            <HashLink
              className="footer__link-nav"
              to="/#services"
              
            >
              Services
            </HashLink>

            <HashLink
              className="footer__link-nav"
              to="/#testimonials"
              
            >
              Testimonials
            </HashLink>

            <HashLink
                className="footer__link-nav"
                to="/#faq"
                
              >
                FAQ
              </HashLink>

              
              </>
              
            )}

            
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
